import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as UserCreators } from 'store/userStore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import FullscreenModal from 'components/atoms/Modal/FullscreenModal';
import DealerAvatar from './DealerAvatar';
import SearchInput from 'components/atoms/Input/SearchInput';
import DealerService from 'services/DealerService';
import { DefaultTheme } from 'styles/theme/defaultTheme';

const ToggleDealerButton = withStyles(() => ({
  root: {
    color: '#424242',
    fontWeight: 400,
    background: '#fff',
    textTransform: 'unset',
    borderRadius: '12px !important',
    border: '1px solid #E0E0E0 !important',
    transition: 'all .2s',
    padding: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    '&:hover': {
      backgroundColor: '#F0F6F6 !important',
    },
    '& .MuiGrid-item': {
      padding: '6px',

      '& p': {
        textAlign: 'initial',
      },
    },
  },
  selected: {
    backgroundColor: '#E0F3F1 !important',
    border: '1px solid #53CBBA !important',
  },
  label: {
    display: 'flex',
    textAlign: 'initial',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '5px',
    color: '#424242',
  },
}))(ToggleButton);

const GroupGrid = withStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '16px',
  },
  '@media (max-width: 960px)': {
    root: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: '8px',
    },
  },
}))(ToggleButtonGroup);

const backDropStyles = makeStyles<DefaultTheme>(({ zIndex }) =>
  createStyles({
    backDrop: {
      zIndex: zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const DealersModalComponent = ({
  dealers = [],
  selectModalActions,
  searchValue,
  handleKeyPress,
  handleInputChange,
  handleDealerOnClick,
  page,
  totalPages,
  handlePageChange,
  isLoading,
}) => {
  const backdrop = backDropStyles();

  const currentDealer = useSelector(
    (state: any) => state.userStore?.currentDealer,
  );
  const dispatch = useDispatch();

  const getDealerById = async (dealerId) => {
    try {
      const response: any = await DealerService.getDealerById(dealerId);
      if (response.ok) {
        dispatch(
          UserCreators.setCurrentDealer({ ...response.data, id: dealerId }),
        );
        dispatch(UserCreators.getMobicoinBalance());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (event, dealer) => {
    if (!dealer?.id) {
      return;
    }
    localStorage.setItem('dealerId', JSON.stringify(dealer?.id));
    await getDealerById(dealer.id);
  };

  return (
    <>
      <FullscreenModal
        modalActions={selectModalActions}
        headerTitle="Selecione uma Loja"
      >
        <Box component="div" px={{ xs: 2, md: 3 }} pb={{ xs: 2, md: 3 }}>
          <Box component="div" display="flex" flexDirection="column" gap="16px">
            <SearchInput
              searchValue={searchValue}
              handleInputChange={handleInputChange}
              handleSearchOnClick={handleDealerOnClick}
              handleKeyPress={handleKeyPress}
            />
            <GroupGrid exclusive value={currentDealer} onChange={handleChange}>
              {dealers?.map((dealer) => (
                <ToggleDealerButton
                  key={dealer?.id}
                  value={dealer}
                  onClick={() => selectModalActions?.handleCloseModal()}
                >
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    gap="8px"
                  >
                    {dealer?.logo ? (
                      <DealerAvatar
                        dealerName={dealer?.name}
                        imageId={dealer?.logo?.imageId}
                      />
                    ) : (
                      <Avatar alt={`${dealer?.name} Logo`} />
                    )}
                    <Box component="p">{`${dealer?.name} - ${dealer?.id}`}</Box>
                  </Box>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={6} md={2} p={2}>
                      <Box
                        component="p"
                        fontSize={{ xs: '12px', md: '14px' }}
                      >{`Tipo: ${dealer?.type}`}</Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Box
                        component="p"
                        fontSize={{ xs: '12px', md: '14px' }}
                      >{`Estado: ${
                        dealer?.state ? `${dealer?.state}` : ''
                      }`}</Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Box
                        component="p"
                        fontSize={{ xs: '12px', md: '14px' }}
                      >{`Endereço: ${
                        dealer?.address
                          ? `${dealer?.address}, ${
                              dealer?.addressNumber ? dealer?.addressNumber : ''
                            }`
                          : ''
                      }`}</Box>
                    </Grid>
                  </Grid>
                </ToggleDealerButton>
              ))}
            </GroupGrid>
            <Pagination
              page={page}
              count={totalPages}
              onChange={handlePageChange}
            />
          </Box>
          <Backdrop className={backdrop.backDrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Box>
      </FullscreenModal>
    </>
  );
};

export default DealersModalComponent;
